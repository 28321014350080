import dayjs from 'dayjs';
import { displayError } from 'utils/helpers';

import { US_STATES } from '../constants';

const states = US_STATES.map(state => state.value);

export const isValidDate = (date: string, format = 'MM/DD/YYYY') => {
  date = normalizeDate(date);

  if (date) {
    const validFormat = dayjs(date, format).format(format) === date;
    if (validFormat) {
      return dayjs(date, format).isBefore(dayjs());
    }
    return false;
  }
  return false;
};
// validate date of birth, it must be 18+ years old
export const isOfAge = (date: string) => {
  if (dayjs().diff(dayjs(date, 'MM/DD/YYYY'), 'year') < 18) {
    return false;
  }
  return true;
};

/**
 * Normalizes the date by the format 'MM/DD/YYYY'
 *
 * @param {string} input the current typed date
 */
export const normalizeDate = (input = '') => {
  if (typeof input !== 'string') {
    return '';
  }

  if (!input) {
    return input;
  }

  const cur = input.replace(/[^\d]/g, '');
  const curLen = cur.length;

  if (curLen < 3) {
    return cur;
  }

  if (curLen < 5) {
    return `${cur.slice(0, 2)}/${cur.slice(2)}`;
  }

  return `${cur.slice(0, 2)}/${cur.slice(2, 4)}/${cur.slice(4, 8)}`;
};

export const isValidEmail = (value: string) => {
  if (value) {
    const reg = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    return reg.test(value);
  }
  return false;
};

export const isValidId = (value: string, format: RegExp | string) => {
  if (value) {
    const reg = new RegExp(format);
    return reg.test(value);
  }
  return false;
};

export const isValidState = (value: string, disabledStates: string[] = []) => {
  if ([...disabledStates, 'Select State'].includes(value)) {
    displayError(
      'Shipping is disabled for the selected State',
      'Please try again or contact our support team.',
    );
    return false;
  }

  return true;
};

/**
 * Filters the US_STATES, removing the states that are disabled for shipping.
 *
 * @param {array} disabledStates the list of disables states acronyms.
 */
export const getShippableStates = (disabledStates: string[] = []) => {
  if (disabledStates.length === 0) return US_STATES;

  return US_STATES.filter((state) => {
    return !disabledStates.includes(state.value);
  });
};

/**
 * Normalizes the phone number by the format '123-456-7890'
 *
 * @param {string} input the current typed phone
 */
export const normalizePhone = (input: string) => {
  if (typeof input !== 'string') {
    return '';
  }

  if (!input) {
    return input;
  }

  const cur = input.replace(/[^\d]/g, '');
  const curLen = cur.length;

  if (curLen < 4) {
    return cur;
  }

  // Removes "1" from the beginning of the number
  // returns format XXX-XXX-XXXX

  if (curLen === 11 && +cur[0] === 1) {
    return `${cur.slice(1, 4)}-${cur.slice(4, 7)}-${cur.slice(7, 11)}`;
  }

  if (curLen < 7) {
    return `${cur.slice(0, 3)}-${cur.slice(3)}`;
  }

  return `${cur.slice(0, 3)}-${cur.slice(3, 6)}-${cur.slice(6, 10)}`;
};

export const isValidZipcode = (zipcode: string) => {
  const reg = new RegExp(/^[0-9]{5}$/);
  return reg.test(zipcode);
};

export const formatDateOfBirth = (date = '') => {
  return dayjs(date).format('YYYY-MM-DD');
};

/**
 * Shipping Method for product fufillment
 * @typedef {Object} ShippingMethod
 * @property {number|string} id - Unique Shipping Method ID
 * @property {string} name - Name of the Shipping Method
 * @property {string} value - Shipping Method Value [Ex. `standard` | `overnight` ]
 * @property {string} description - Estimate of arrival time
 * @property {number} price - Price of the Shipping Method
 */

/**
 * Delivery Option for use in the DeliveryOptions component
 * @typedef {Object} DeliveryOption
 * @property {string} id - Unique Delivery Option ID
 * @property {string} name - Name of Delivery Option
 * @property {string} value - Value of Delivery Option
 * @property {string} title - Title of Delivery Option
 * @property {number} price - Price of Delivery Option
 * @property {string} estimate - Estimate of Arrival Time
 */

/**
 * Formats a `ShippingMethod` object into a `DeliveryOption` object
 *
 * @param {ShippingMethod} shippingMethod A shipping method to be formatted
 * @returns {DeliveryOption} a delivery option
 */
export const deliveryOptionFormat = (shippingMethod: DeliveryOption): ShippingMethod => {
  return {
    id: shippingMethod?.id,
    name: shippingMethod?.value,
    value: shippingMethod?.value,
    title: shippingMethod?.name,
    price: shippingMethod?.price,
    estimate: shippingMethod?.description,
  };
};

/**
 * Shipping Option for use with the StripePaymentRequest
 * @typedef {Object} ShippingOption
 * @property {string} id - A unique ID you create to keep track of this shipping option.
 * @property {string} label - A short label for this shipping option.
 * @property {string} detail - A longer description of this shipping option.
 * @property {number} amount - The amount to show for this shipping option.
 */

/**
 * Formats a `ShippingMethod` object into a `ShippingOption` object
 * for Stripe PaymentRequest
 *
 * @param {ShippingMethod} shippingMethod a shipping method to be formatted
 * @returns {ShippingOption} a shipping option
 */
export const shippingOptionFormat = (shippingMethod: DeliveryOption) => {
  return {
    id: shippingMethod?.value,
    label: shippingMethod?.name,
    detail: shippingMethod?.description,
    amount: +`${shippingMethod?.price}00`,
  };
};
